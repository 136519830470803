import React, { useState, useEffect } from 'react';
import './App.css';
import { FaTwitter, FaTelegram } from 'react-icons/fa';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Analytics } from '@vercel/analytics/react';

function App() {
  const tokenData = {
    PLS: {
      symbol: 'PLS',
      image: '/images/pls.png'
    },
    HEX: {
      symbol: 'HEX',
      image: '/images/hex.png'
    },
    PLSX: {
      symbol: 'PLSX',
      image: '/images/plsx.png'
    },
    INC: {
      symbol: 'INC',
      image: '/images/inc.png'
    }
  };

  const tokens = Object.keys(tokenData);

  const [selectedTab, setSelectedTab] = useState('hex'); // Changed default to 'hex'
  const [reserveAmount, setReserveAmount] = useState('171088');  // Changed to '0'
  const [ethPrice, setEthPrice] = useState('3400');
  const [inputFields, setInputFields] = useState(2); // Default to 2 fields for HEX/PLSX
  const [inputValues, setInputValues] = useState([
    { amount: '', token: 'HEX' },
    { amount: '', token: 'PLSX' }
  ]); // Default setup for HEX/PLSX
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([
    { ticker: 'PLS', initialPrice: '0.000058', newPrice: '0.000058', increase: '2' },
    { ticker: 'PLSX', initialPrice: '0.000058', newPrice: '0.00007', increase: '2' },
    { ticker: 'INC', initialPrice: '0.00007', newPrice: '0.00007', increase: '2' },
    { ticker: 'HEX', initialPrice: '0.000058', newPrice: '0.00007', increase: '2' }
  ]);
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  // Helper function to format numbers with commas
  const formatNumber = (num) => {
    return Math.round(num).toLocaleString('en-US');
  };

  useEffect(() => {
    // Watch for tab changes and update distributions
    const updateDistribution = () => {
      const reserveValue = parseInt(reserveAmount.replace(/,/g, '')) || 0;
      
      switch(selectedTab) {
        case 'hex':
          // Split between HEX and PLSX
          const splitTwo = Math.round(reserveValue / 2);
          setInputFields(2);
          setInputValues([
            { amount: formatNumber(splitTwo), token: 'HEX' },
            { amount: formatNumber(splitTwo), token: 'PLSX' }
          ]);
          break;
          
        case 'hexplsx':
          // Split between HEX, PLSX, and INC
          const splitThree = Math.round(reserveValue / 3);
          setInputFields(3);
          setInputValues([
            { amount: formatNumber(splitThree), token: 'HEX' },
            { amount: formatNumber(splitThree), token: 'PLSX' },
            { amount: formatNumber(splitThree), token: 'INC' }
          ]);
          break;
          
        case 'even':
          // Split between all four tokens
          const splitFour = Math.round(reserveValue / 4);
          setInputFields(4);
          setInputValues([
            { amount: formatNumber(splitFour), token: 'HEX' },
            { amount: formatNumber(splitFour), token: 'PLSX' },
            { amount: formatNumber(splitFour), token: 'INC' },
            { amount: formatNumber(splitFour), token: 'PLS' }
          ]);
          break;
          
        case 'custom':
          // Reset to single HEX field with 0
          setInputFields(1);
          setInputValues([
            { amount: '0', token: 'HEX' }
          ]);
          break;
      }
    };

    updateDistribution();
  }, [selectedTab, reserveAmount]); // Depend on tab changes and reserve amount changes

  useEffect(() => {
    const fetchEthPrice = async () => {
      let cronApiUrl = '';
      let fullUrl = '';
      
      try {
        cronApiUrl = process.env.REACT_APP_CRON_API_URL?.trim() || '';
        fullUrl = `${cronApiUrl}/api/get-eth-price`;
        
        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        const data = await response.json();
        if (data.price) {
          const ethPriceValue = Math.round(data.price).toString();  // Round to nearest whole number
          setEthPrice(ethPriceValue);
          
          // Calculate and set reserve amount
          const reserveEth = 171088;
          const reserveUsd = Math.round(reserveEth * parseFloat(ethPriceValue));
          setReserveAmount(formatNumber(reserveUsd));
        }
      } catch (error) {
        console.error('Error details:', {
          message: error.message,
          type: error.type,
          url: cronApiUrl,
          fullUrl: fullUrl
        });
      }
    };

    fetchEthPrice();
    
    const interval = setInterval(fetchEthPrice, 5 * 60 * 1000);
    
    return () => clearInterval(interval);
  }, []);

  const getAvailableTokens = (currentIndex) => {
    const selectedTokens = inputValues.map(input => input.token);
    return tokens.filter((token, index) => !selectedTokens.includes(token) || selectedTokens.indexOf(token) === currentIndex);
  };

  const handleAddField = () => {
    if (inputFields < 4) {
      setShowResults(false); // Hide results when adding a field
      
      // Switch to custom split tab if on hex or hexplsx tab
      if (selectedTab === 'hex' || selectedTab === 'hexplsx') {
        setSelectedTab('custom');
      }
      
      const currentTokens = inputValues.map(input => input.token);
      const availableTokens = tokens.filter(token => !currentTokens.includes(token));
      const defaultToken = availableTokens[0] || tokens[0];

      setInputFields(inputFields + 1);
      setInputValues([...inputValues, { amount: '', token: defaultToken }]);
    }
  };

  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);
    setShowResults(false);
  };

  const handleInputChange = (index, value) => {
    setShowResults(false); // Hide results on input change
    const newValues = [...inputValues];
    // Remove any non-numeric characters and format the number
    const numericValue = value.replace(/[^0-9]/g, '');
    newValues[index].amount = formatNumber(numericValue);
    setInputValues(newValues);
  };

  const handleTokenChange = (index, token) => {
    const isTokenUsed = inputValues.some((input, i) => i !== index && input.token === token);
    
    if (!isTokenUsed) {
      const newValues = [...inputValues];
      newValues[index].token = token;
      setInputValues(newValues);
    }
  };

  const handleCalculate = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || '';
      const sacMoney = inputValues.map(input => input.amount.replace(/,/g, '') || '0').join(',');
      const tickers = inputValues.map(input => input.token).join(',');
      
      const response = await fetch(
        `${apiUrl}/calculate?sacMoney=${sacMoney}&ticker=${tickers}&adjustmentPercentage=0`
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      const formatPrice = (price) => {
        const numPrice = parseFloat(price);
        
        if (numPrice < 0.0001) {
          // For very small numbers (0.000042), show 6 decimal places
          return numPrice.toFixed(6);
        } else if (numPrice < 0.01) {
          // For just small numbers (0.0034), show 4 decimal places
          return numPrice.toFixed(4);
        } else if (numPrice < 0.1) {
          // For small numbers (0.034), show 3 decimal places
          return numPrice.toFixed(3);
        } else {
          // For larger numbers (1.29), show 2 decimal places
          return numPrice.toFixed(2);
        }
      };

      const newResults = tokens.map(token => {
        const initialPrice = parseFloat(data.originalPricesAndLPValues[`price${token}`]);
        const newPrice = parseFloat(data.final_results[`price${token}`]);
        
        // Round the increase to the nearest whole number
        const increase = Math.round((newPrice / initialPrice));

        return {
          ticker: token,
          initialPrice: formatPrice(initialPrice),
          newPrice: formatPrice(newPrice),
          increase: increase.toString()
        };
      });

      setResults(newResults);
      setShowResults(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleReserveChange = (value) => {
    setShowResults(false); // Hide results when reserve amount changes
    const numericValue = value.replace(/[^0-9]/g, '');
    setReserveAmount(formatNumber(numericValue));
  };

  const handleEthPriceChange = (value) => {
    setShowResults(false); // Hide results when ETH price changes
    const numericValue = value.replace(/[^0-9$]/g, '').replace('$', '');
    setEthPrice(numericValue);
    
    // Calculate and update reserve amount based on new ETH price
    const reserveEth = 171088; // Fixed amount of ETH in reserve
    const reserveUsd = Math.round(reserveEth * parseFloat(numericValue));
    setReserveAmount(formatNumber(reserveUsd));
  };

  const tabs = [
    { id: 'custom', label: 'Custom Split' },
    { id: 'even', label: 'Even Split' },
    { id: 'hex', label: 'HEX/PLSX' },
    { id: 'hexplsx', label: 'HEX/PLSX/INC' },
  ];

  const getUnselectedTokens = () => {
    const selectedTokens = inputValues.map(input => input.token);
    return tokens.filter(token => !selectedTokens.includes(token));
  };

  return (
    <div className="NewUi">
      <div className="AppHeader">
        <div className="Header">
          <div className="LogoSection">
            <img 
              src="/logo192.png"
              alt="PAMPI Logo" 
              className="PampiLogo"
            />
            <div className="Pampi">PAMPI</div>
          </div>
          <div className="Socials">
            <a 
              href="https://twitter.com/Roonskies93" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaTwitter className="social-icon" />
            </a>
            <a 
              href="https://t.me/Roonskies" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaTelegram className="social-icon" />
            </a>
          </div>
        </div>
        <div className="Line">
          <div className="Line1"></div>
        </div>
      </div>

      <div className="calculator">
        <div className="Heading">
          <div className="PriceAppreciationSimulator">
            Price Appreciation Simulator
          </div>
        </div>

        <div className="CalcSection">
          <div className="ChipSection">
            <div className="ChipTabs">
              {tabs.map(tab => (
                <div
                  key={tab.id}
                  className={`${tab.id === selectedTab ? 'CustomTab' : ''}`}
                  onClick={() => handleTabChange(tab.id)}
                >
                  <div className={tab.id === selectedTab ? 'Selected' : 'Unselected'}>
                    {tab.label}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={`InputSectionFrame ${inputValues.length > 2 ? 'InputSectionFrame-Large' : 'InputSectionFrame-Small'}`}>
            <div className={`CalcCore ${inputValues.length > 2 ? 'CalcCore-Large' : 'CalcCore-Small'}`}>
              <div className="Frame22">
                <div className="Calculator">Calculator</div>
                <button className="Button" onClick={handleAddField}>
                  <div className="IconPlus">
                    <div className="Vector"></div>
                    <div className="Vector"></div>
                  </div>
                </button>
              </div>

              <div className="InputFieldsContainer">
                <div className="InputRow">
                  {inputValues.slice(0, 2).map((field, index) => (
                    <div 
                      key={index}
                      className={`InputField ${inputValues.length === 1 ? 'InputField-FirstRow-Large' : 'InputField-FirstRow-Small'}`}
                    >
                      <div className="amount-input">
                        <span className="dollar-prefix">$</span>
                        <input
                          type="text"
                          value={field.amount}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          placeholder="0"
                        />
                      </div>
                      <div className="token-dropdown">
                        <img 
                          src={tokenData[field.token].image} 
                          alt={field.token} 
                          className="token-icon"
                        />
                        <select
                          value={field.token}
                          onChange={(e) => handleTokenChange(index, e.target.value)}
                        >
                          {getAvailableTokens(index).map(token => (
                            <option key={token} value={token}>
                              {token}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}
                </div>
                
                {inputValues.length > 2 && (
                  <div className="InputRow">
                    {inputValues.slice(2).map((field, index) => (
                      <div 
                        key={index + 2}
                        className={`InputField ${inputValues.length === 3 ? 'InputField-SecondRow-Large' : 'InputField-SecondRow-Small'}`}
                      >
                        <div className="amount-input">
                          <span className="dollar-prefix">$</span>
                          <input
                            type="text"
                            value={field.amount}
                            onChange={(e) => handleInputChange(index + 2, e.target.value)}
                            placeholder="0"
                          />
                        </div>
                        <div className="token-dropdown">
                          <img 
                            src={tokenData[field.token].image} 
                            alt={field.token} 
                            className="token-icon"
                          />
                          <select
                            value={field.token}
                            onChange={(e) => handleTokenChange(index + 2, e.target.value)}
                          >
                            {getAvailableTokens(index + 2).map(token => (
                              <option key={token} value={token}>
                                {token}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <button className="calc-btn" onClick={handleCalculate}>
                <div className="Continue">Calculate</div>
              </button>
            </div>

            <div className={`InfoLayer ${inputValues.length > 2 ? 'InfoLayer-Large' : 'InfoLayer-Small'}`}>
              <div className="InputGroup">
                <span className="InputLabel">Pulsechain Reserve:</span>
                {/* <span className="InputPrefix">$</span> */}
                <input
                  type="text"
                  value={`$${reserveAmount}`}
                  onChange={(e) => handleReserveChange(e.target.value)}
                  className="InfoInput"
                />
              </div>
              <div className="InputGroup">
                <span className="InputLabel">ETH:</span>
                {/* <span className="InputPrefix">$</span> */}
                <input
                  type="text"
                  value={`$${ethPrice}`}
                  onChange={(e) => handleEthPriceChange(e.target.value)}
                  className="InfoInputETH"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`ResultsSection ${showResults ? 'visible' : ''}`}>
          {showResults ? (
            <>
              <div className="ResultsHeading">
                <div className="ResultsHeading-heading">
                  <div className="Results">Results</div>
                  <button className="ShareButton">
                    <div className="Cancel">share</div>
                  </button>
                </div>
              </div>
              
              <div className="Table">
                <div className="ResultsHeadings">
                  <div className="Ticker">
                    <div className="TickerText">Ticker</div>
                  </div>
                  <div className="InitialPrice">
                    <div className="InitialPriceText">Initial Price</div>
                  </div>
                  <div className="NewPrice" style={{ minWidth: '173px' }}>
                    <div className="NewPriceText">New Price</div>
                  </div>
                </div>
                {results.filter(result => inputValues.some(input => input.token === result.ticker)).map((result, index) => (
                  <div key={index} className="TableRow">
                    <div className="TableItem">
                      <img className="TickerIcon" src={tokenData[result.ticker].image} alt={result.ticker} />
                      <span className="TickerLabel">{result.ticker}</span>
                    </div>
                    <div className="TableItem">
                      <span className="PriceLabel">${result.initialPrice}</span>
                    </div>
                    <div className="TableItem" style={{ minWidth: '173px' }}>
                      <span className="PriceLabel">${result.newPrice}</span>
                      <div className="IncreaseIndicator">
                        <div className="ArrowForward">
                          <i className="fas fa-arrow-up Vector"></i>
                        </div>
                        <span className="IncreaseText">{result.increase}x</span>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="ReflexivityHeader">
                  <div className="TableItem">
                    <span className="ReflexivityText">Reflexivity</span>
                  </div>
                </div>

                {results.filter(result => !inputValues.some(input => input.token === result.ticker)).map((result, index) => (
                  <div key={`unselected-${index}`} className="TableRow">
                    <div className="TableItem">
                      <img className="TickerIcon" src={tokenData[result.ticker].image} alt={result.ticker} />
                      <span className="TickerLabel">{result.ticker}</span>
                    </div>
                    <div className="TableItem">
                      <span className="PriceLabel">${result.initialPrice}</span>
                    </div>
                    <div className="TableItem">
                      <span className="PriceLabel">${result.newPrice}</span>
                      <div className="IncreaseIndicator">
                        <div className="ArrowForward">
                          <i className="fas fa-arrow-up Vector"></i>
                        </div>
                        <span className="IncreaseText">{result.increase}x</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : showDisclaimer ? (
            <div className="DisclaimerSection">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="DisclaimerTitle">Disclaimer</div>
                <button 
                  className="ShareButton" 
                  onClick={() => setShowDisclaimer(false)}
                  style={{ padding: '4px 8px' }}
                >
                  <div className="Cancel">✕</div>
                </button>
              </div>
              <div className="DisclaimerText">
                Please interpret the numbers with caution, as they do not account for market buy/sell pressures. This app is designed to illustrate price reflexivity.
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Analytics />
    </div>
  );
}

export default App;